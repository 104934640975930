import {
  StatusTransactionEnum,
  UpdateRequestPaymentFormValues,
} from '../upgrade-account-management/core/_models'

export type String = string | undefined | null
export type Boolean = boolean | undefined | null
export type Number = number | undefined | null
export enum DemandEnum {
  SELL = 'sell',
  FOR_RENT = 'forRent',
}
export enum GenderTypeEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}
export enum TagEnum {
  JOB = 'JOB',
  DEFAULT = 'DEFAULT',
}
export enum MaritalStatusEnum {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
}
export enum TypeNotarizationEnum {
  FLUCTUATION = 'FLUCTUATION',
  BANK_LOANS = 'BANK_LOANS',
  NOTARIZATION = 'NOTARIZATION',
}
export enum StatusEnum {
  PENDING_PROCESSING = 'PENDING_PROCESSING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export type Map =
  | {
      lat?: String
      long?: String
    }
  | undefined
  | null
export type Range =
  | {
      min?: Number
      max?: Number
    }
  | undefined
  | null
export type ResponseUserSerialization =
  | {
      _id: String
      fullName?: String
      email?: String
      phone?: String
      avatar?: String
    }
  | undefined
  | null
export type ProvinceSerialization =
  | {
      id: String
      name?: String
    }
  | undefined
  | null
export type DistrictSerialization =
  | {
      id: String
      name?: String
    }
  | undefined
  | null
export type WardSerialization =
  | {
      id: String
      name?: String
    }
  | undefined
  | null
export type StreetSerialization =
  | {
      id: String
      name?: String
    }
  | undefined
  | null
export type ResponseImageSerialization =
  | {
      fileId: String
      type?: String
      originalUrl?: String
      urls?: ITypeUrls[]
      createdAt?: String
      creator?: ResponseUserSerialization
      mimetype?: string
      size?: number
      name?: String
    }
  | undefined
  | null
export type ResponseAttachmentSerialization =
  | {
      fileId: String
      type?: String
      originalUrl?: String
      urls?: ITypeUrls[]
      createdAt?: String
      creator?: ResponseUserSerialization
      mimetype?: string
      size?: number
      name?: String
    }
  | undefined
  | null
export type ITypeUrls =
  | {
      type?: String
      url?: String
    }
  | undefined
  | null
export type ResponseTagSerialization =
  | {
      _id: String
      name?: String
      description?: String
    }
  | undefined
  | null
export type CountrySerialization =
  | {
      name?: String
    }
  | undefined
  | null

export interface ResponseGraphQL<T> {
  data?: T
}

export const initialFormValues: UpdateRequestPaymentFormValues = {
  status: StatusTransactionEnum.PENDING,
  requestStatus: undefined,
  bankOwner: '',
  bankCode: '',
  bankAccountNumber: '',
  amount: '',
  transactionId: '',
  body: '',
  invoiceDate: '',
  reason: 'notPay',
}
