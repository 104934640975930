import clsx from 'clsx'
import {FileWithFullUrls, useAuth} from '../../../../app/modules/auth'
import {HeaderUserMenu} from '../../../partials'
import {first, isNil, upperCase} from 'lodash'
import {SpotlightForAdmin} from './header-menus/SpotlightForAdmin'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-36px symbol-md-40px'
//const BihaId = process.env.REACT_APP_BIHA_ID

const Navbar = () => {
  const {currentUser} = useAuth()

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        {/* {isEqual(currentUser?.company, BihaId) && (
          <div
            className='app-navbar-item cursor-pointer symbol h-full'
          >
            <SpotlightForAdmin />
          </div>
        )} */}
        <div className='cursor-pointer symbol h-full'>
          <SpotlightForAdmin />
        </div>
        <span className='ki-duotone ki-notification-on text-white fs-1 me-5'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
          <span className='path4'></span>
          <span className='path5'></span>
        </span>
        <div
          className={clsx('cursor-pointer symbol d-flex align-items-center', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {!isNil((currentUser?.avatar as FileWithFullUrls)?.originalUrl) ? (
            <img
              src={(currentUser?.avatar as FileWithFullUrls)?.originalUrl}
              alt=''
              className='rounded w-36px h-36px me-3'
            />
          ) : (
            <div className='symbol-label rounded w-36px h-36px fs-4 text-gray-700 me-3'>
              {upperCase(first(currentUser?.fullName))}
            </div>
          )}
          <span className='fs-5 fw-bold text-white me-2'>{currentUser?.fullName}</span>
          <span className='ki-outline ki-down text-white'></span>
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
