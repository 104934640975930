import axios, {AxiosResponse} from 'axios'
import {ConfigsQueryResponseGraphQL, GetInternalRealEstateConfigurationsQueryData} from './_models'

const BIHA_API_ENDPOINT = process.env.REACT_APP_BIHA_API_ENDPOINT
const MAIN_CONFIG_API_ENDPOINT = `${BIHA_API_ENDPOINT}/biha/graphql`

const getInternalRealEstateConfigurations = async (): Promise<
  GetInternalRealEstateConfigurationsQueryData | undefined
> => {
  const response: AxiosResponse<ConfigsQueryResponseGraphQL> = await axios.post(
    MAIN_CONFIG_API_ENDPOINT,
    {
      query: `
      query {
        getInternalRealEstateConfigurations {
          common {
            paymentMethodType {
              key
              value
            }
            upgradeRequestRankType {
              key
              value
            }
            paymentAmountType {
              key
              value
            }
            upgradeRequestStatusType {
              key
              value
            }
            banks {
              key
              value
            }
            paymentStatusType {
              key
              value
            }
            paymentType {
              key
              value
            }
          }
        }
      }
    `,
    }
  )
  return response.data.data
}

export {getInternalRealEstateConfigurations}
