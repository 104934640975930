import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {LocaleProvider} from '../_biha/i18n/LocaleProvider'
import {MasterInit} from '../_biha/layout/MasterInit'
import {LayoutProvider, LayoutSplashScreen} from '../_biha/layout/core'
import {AuthInit} from './modules/auth'
import {AddressProvider} from './modules/major/address/AddressProvider'
import {BankListProvider} from './modules/major/bank/BankListProvider'
import {ConfigurationGraphqlProvider} from './modules/major/config-ewallet/ConfigurationProvider'
import {ConfigurationProvider} from './modules/major/config/ConfigurationProvider'
import {UserListProvider} from './modules/major/user/UserListProvider'
import {UpgradeAccountConfigurationProvider} from './modules/major/account/UpgradeAccountProvider'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LocaleProvider>
        <LayoutProvider>
          <AuthInit>
            <AddressProvider>
              <ConfigurationProvider>
                <ConfigurationGraphqlProvider>
                  <UpgradeAccountConfigurationProvider>
                    <UserListProvider>
                      <BankListProvider>
                        <Outlet />
                        <MasterInit />
                      </BankListProvider>
                    </UserListProvider>
                  </UpgradeAccountConfigurationProvider>
                </ConfigurationGraphqlProvider>
              </ConfigurationProvider>
            </AddressProvider>
          </AuthInit>
        </LayoutProvider>
      </LocaleProvider>
    </Suspense>
  )
}

export {App}
