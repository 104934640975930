import {StatusEnum} from '../../../../../app/modules/apps/bank-loan-request-management/core/_models'
import {Module} from '../../../../../app/modules/auth'
import {
  BANK_MAIN_PATHNAME,
  BANK_SUB_PATHNAME,
  FLUCTUATION_MAIN_PATHNAME,
  FLUCTUATION_SUB_PATHNAME,
  NOTARIZATION_MAIN_PATHNAME,
  NOTARIZATION_SUB_PATHNAME,
} from '../../../../helpers'

export interface RouterProps {
  id: number
  path?: string
  title?: string
  icon?: string
  module?: Module
}

export const routersApp: RouterProps[] = [
  {
    id: 0,
    title: 'Tất cả',
  },
  {
    id: 1,
    path: process.env.REACT_APP_CRM_URL,
    title: 'Quản lý CRM',
    icon: '/media/biha/icons/modules/crm-management.svg',
    module: Module.CRM,
  },
  {
    id: 2,
    path: process.env.REACT_APP_PROJECT_MANAGEMENT_URL,
    title: 'Quản lý dự án',
    icon: '/media/biha/icons/modules/project-management.svg',
    module: Module.PROJECT,
  },
  {
    id: 3,
    path: process.env.REACT_APP_PRODUCT_MANAGEMENT_URL,
    title: 'Quản lý nhà phố',
    icon: '/media/biha/icons/modules/product-management.svg',
    module: Module.REALESTATE,
  },
  {
    id: 4,
    path: process.env.REACT_APP_ORGANIZATION_MANAGEMENT_URL,
    title: 'Quản lý bộ máy tổ chức',
    icon: '/media/biha/icons/modules/organization-management.svg',
  },
  {
    id: 5,
    path: process.env.REACT_APP_EMPLOYEE_MANAGEMENT_URL,
    title: 'Quản lý nhân viên',
    icon: '/media/biha/icons/modules/employee-management.svg',
  },
  {
    id: 6,
    path: process.env.REACT_APP_CALLCENTER_MANAGEMENT_URL,
    title: 'Tổng đài thông minh',
    icon: '/media/biha/icons/modules/call-center-management.svg',
    module: Module.CALLCENTER,
  },

  {
    id: 7,
    path: process.env.REACT_APP_UPGARADE_ACCOUNT_MANAGEMENT_URL,
    title: 'Quản lý nâng cấp tài khoản ATM Nhà',
    icon: '/media/biha/icons/modules/upgrade-account-management.svg',
    module: Module.UPGRADE_ACCOUNT,
  },
  {
    id: 8,
    path: process.env.REACT_APP_BANK_MANAGEMENT_URL,
    title: 'Quản lý vay vốn ngân hàng',
    icon: '/media/biha/icons/modules/bank-loan-management.svg',
    module: Module.LOAN,
  },
  {
    id: 9,
    path: process.env.REACT_APP_NOTARIZATION_MANAGEMENT_URL,
    title: 'Quản lý hồ sơ công chứng',
    icon: '/media/biha/icons/modules/notarization-request-management.svg',
    module: Module.NOTARIZATION,
  },
  {
    id: 10,
    path: '/fluctuation-requests/list',
    title: 'Quản lý đăng ký biến động',
    icon: '/media/biha/icons/modules/register-request-management.svg',
    module: Module.REGISTER,
  },
  {
    id: 11,
    path: process.env.REACT_APP_POST_ATM_NHA_MANAGEMENT_URL,
    title: 'Quản lý tin đăng lên ATM Nhà',
    icon: '/media/biha/icons/modules/post-to-atmnha-management.svg',
    module: Module.B2B_PUBLIC_REALESTATE,
  },
  {
    id: 12,
    path: process.env.REACT_APP_FORECLOSURE_MANAGEMENT_URL,
    title: 'Quản lý yêu cầu mua tài sản phát mãi',
    icon: '/media/biha/icons/modules/foreclosure-request-management.svg',
    module: Module.FORECLOSURE_REQUEST,
  },
]

export const adminRouters = [
  {
    id: 1,
    path: process.env.REACT_APP_POST_ATM_NHA_MANAGEMENT_URL,
    title: 'Quản lý tin đăng lên ATM Nhà',
    icon: '/media/biha/icons/modules/post-to-atmnha-management.svg',
    module: Module.ADMIN_MANAGE_PUBLIC_REALESTATE,
  },
  {
    id: 2,
    path: process.env.REACT_APP_FORECLOSURE_MANAGEMENT_URL,
    title: 'Quản lý yêu cầu mua tài sản phát mãi',
    icon: '/media/biha/icons/modules/foreclosure-request-management.svg',
    module: Module.ADMIN_FORECLOSURE_REQUEST,
  },
  {
    id: 3,
    path: `/${BANK_MAIN_PATHNAME}/${BANK_SUB_PATHNAME.LIST}`,
    title: 'Quản lý vay vốn ngân hàng',
    icon: '/media/biha/icons/modules/bank-loan-management.svg',
    mainPath: `/${BANK_MAIN_PATHNAME}`,
    module: Module.ADMIN_LOAN,
    subPath: [
      {
        childrenPath: `${BANK_MAIN_PATHNAME}/${BANK_SUB_PATHNAME.LIST}?status=${StatusEnum.PENDING_PROCESSING}`,
        title: 'Yêu cầu đang chờ xử lý',
        param: StatusEnum.PENDING_PROCESSING,
      },
      {
        childrenPath: `${BANK_MAIN_PATHNAME}/${BANK_SUB_PATHNAME.LIST}?status=${StatusEnum.PENDING_APPROVAL}`,
        title: 'Yêu cầu đang chờ duyệt',
        param: StatusEnum.PENDING_APPROVAL,
      },
      {
        childrenPath: `${BANK_MAIN_PATHNAME}/${BANK_SUB_PATHNAME.LIST}?status=${StatusEnum.APPROVED}`,
        title: 'Yêu cầu đã duyệt',
        param: StatusEnum.APPROVED,
      },
      {
        childrenPath: `${BANK_MAIN_PATHNAME}/${BANK_SUB_PATHNAME.LIST}?status=${StatusEnum.REJECTED}`,
        title: 'Yêu cầu đã từ chối',
        param: StatusEnum.REJECTED,
      },
    ],
  },
  {
    id: 4,
    path: `/${NOTARIZATION_MAIN_PATHNAME}/${NOTARIZATION_SUB_PATHNAME.LIST}`,
    title: 'Quản lý hồ sơ công chứng',
    icon: '/media/biha/icons/modules/notarization-request-management.svg',
    mainPath: `/${NOTARIZATION_MAIN_PATHNAME}`,
    module: Module.ADMIN_NOTARIZATION,
    subPath: [
      {
        childrenPath: `${NOTARIZATION_MAIN_PATHNAME}/${NOTARIZATION_SUB_PATHNAME.LIST}?status=${StatusEnum.PENDING_PROCESSING}`,
        title: 'Yêu cầu đang chờ xử lý',
        param: StatusEnum.PENDING_PROCESSING,
      },
      {
        childrenPath: `${NOTARIZATION_MAIN_PATHNAME}/${NOTARIZATION_SUB_PATHNAME.LIST}?status=${StatusEnum.PENDING_APPROVAL}`,
        title: 'Yêu cầu đang chờ duyệt',
        param: StatusEnum.PENDING_APPROVAL,
      },
      {
        childrenPath: `${NOTARIZATION_MAIN_PATHNAME}/${NOTARIZATION_SUB_PATHNAME.LIST}?status=${StatusEnum.APPROVED}`,
        title: 'Yêu cầu đã duyệt',
        param: StatusEnum.APPROVED,
      },
      {
        childrenPath: `${NOTARIZATION_MAIN_PATHNAME}/${NOTARIZATION_SUB_PATHNAME.LIST}?status=${StatusEnum.REJECTED}`,
        title: 'Yêu cầu đã từ chối',
        param: StatusEnum.REJECTED,
      },
    ],
  },
  {
    id: 5,
    path: `${FLUCTUATION_MAIN_PATHNAME}/${FLUCTUATION_SUB_PATHNAME.LIST}`,
    title: 'Quản lý đăng ký biến động',
    icon: '/media/biha/icons/modules/register-request-management.svg',
    mainPath: `${FLUCTUATION_MAIN_PATHNAME}`,
    module: Module.ADMIN_REGISTER,
    subPath: [
      {
        childrenPath: `${FLUCTUATION_MAIN_PATHNAME}/${FLUCTUATION_SUB_PATHNAME.LIST}?status=${StatusEnum.PENDING_PROCESSING}`,
        title: 'Yêu cầu đang chờ xử lý',
        param: StatusEnum.PENDING_PROCESSING,
      },
      {
        childrenPath: `${FLUCTUATION_MAIN_PATHNAME}/${FLUCTUATION_SUB_PATHNAME.LIST}?status=${StatusEnum.PENDING_APPROVAL}`,
        title: 'Yêu cầu đang chờ duyệt',
        param: StatusEnum.PENDING_APPROVAL,
      },
      {
        childrenPath: `${FLUCTUATION_MAIN_PATHNAME}/${FLUCTUATION_SUB_PATHNAME.LIST}?status=${StatusEnum.APPROVED}`,
        title: 'Yêu cầu đã duyệt',
        param: StatusEnum.APPROVED,
      },
      {
        childrenPath: `${FLUCTUATION_MAIN_PATHNAME}/${FLUCTUATION_SUB_PATHNAME.LIST}?status=${StatusEnum.REJECTED}`,
        title: 'Yêu cầu đã từ chối',
        param: StatusEnum.REJECTED,
      },
    ],
  },
  {
    id: 6,
    path: process.env.REACT_APP_CUSTOMER_MANAGEMENT_URL,
    title: 'Quản lý khách hàng ATM Nhà & Biha',
    icon: '/media/biha/icons/modules/customerATM-and-biha-management.svg',
    module: Module.ADMIN_BUSINESS_ACCOUNT,
  },
]
