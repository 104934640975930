import axios from 'axios'
import {B2bGetMeData, B2bSignInData, B2bSignInVariables} from './_models'

const AUTH_API_ENDPOINT = process.env.REACT_APP_AUTH_API_ENDPOINT

export const AUTH_USERS_API_ENDPOINT = `${AUTH_API_ENDPOINT}/users/graphql`
// export const REGISTER_URL = `${AUTH_API_URL}/register`
// export const REQUEST_PASSWORD_URL = `${AUTH_API_URL}/forgot_password`

// Server should return B2bSignInData
export function b2bSignIn(username: string, password: string) {
  return axios.post<B2bSignInVariables, B2bSignInData>(AUTH_USERS_API_ENDPOINT, {
    query: `
        mutation ($username: String!, $password: String!) {
          b2bSignIn(username: $username, password: $password) {
            success
            msg
            payload {
              id
              accessToken
              refreshToken
            }
          }
        }
      `,
    variables: {
      username,
      password,
    },
  })
}

// Server should return AuthModel
// export function register(
//   email: string,
//   firstname: string,
//   lastname: string,
//   password: string,
//   password_confirmation: string
// ) {
//   return axios.post(REGISTER_URL, {
//     email,
//     first_name: firstname,
//     last_name: lastname,
//     password,
//     password_confirmation,
//   })
// }

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   })
// }

export function b2bGetMe(accessToken: string) {
  return axios.post<B2bGetMeData>(
    AUTH_USERS_API_ENDPOINT,
    {
      query: `
      query {
        b2bGetMe {
          success
          msg
          payload {
            id
            fullName
            avatar {
              ... on FileWithFullUrls {
                fileId
                originalUrl
              }
            }
            tableSettings {
              tableId
              columns {
                checked
                id
                label
              }
            }
            phone
            email
            modules
            company
          }
        }
      }`,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}
