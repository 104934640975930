import {String} from '../../../auth'

export type JSONValue = string | number | boolean | JSONObject | JSONArray
export interface JSONObject {
  [field: string]: JSONValue
}
export type JSONArray = Array<JSONValue>
export interface GetBankListQueryData {
  data?: {
    getListBank?: JSONObject[]
  }
}
export interface ListCompaniesByTypeQueryVariables {
  bankCode?: String
  district?: String
  province?: String
}
export interface ListCompaniesByTypeQueryData {
  data?: {
    listCompaniesByType?: {
      companies?: Company[]
    }
  }
}
export interface Company {
  id?: String
  name?: String
}
export enum CompanyEnum {
  estate = 'estate',
  construction = 'construction',
  law = 'law',
  supplier = 'supplier',
  cj = 'cj',
  car = 'car',
  bank = 'bank',
}
