import clsx from 'clsx'
import {concat, find, includes, isEmpty, isEqual, join} from 'lodash'
import {FC} from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {StatusEnum} from '../../../../../app/modules/apps/core/_models'
import {KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

interface Props {
  to: string
  title: string
  fontIcon?: string
  hasBullet?: boolean
  param?: string
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  fontIcon,
  hasBullet = false,
  param,
}) => {
  const {pathname, search} = useLocation()
  const isActive =
    isEmpty(search) && isEqual(param, StatusEnum.PENDING_PROCESSING)
      ? true
      : includes(join(concat(pathname, search), ''), param)
  const {config} = useLayout()
  const {app} = config
  const sidebarMenuData = [
    {
      id: 1,
      param: StatusEnum.PENDING_PROCESSING,
      icon: (
        <i className='ki-duotone ki-notepad-edit fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      ),
    },
    {
      id: 2,
      param: StatusEnum.PENDING_APPROVAL,
      icon: (
        <i className='ki-duotone ki-update-file fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
          <span className='path4'></span>
        </i>
      ),
    },
    {
      id: 3,
      param: StatusEnum.APPROVED,
      icon: (
        <i className='ki-duotone ki-tablet-ok fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
        </i>
      ),
    },
    {
      id: 4,
      param: StatusEnum.REJECTED,
      icon: (
        <i className='ki-duotone ki-tablet-delete fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
        </i>
      ),
    },
  ]
  const menuItemData = find(sidebarMenuData, (menu) => isEqual(menu?.param, param))

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {menuItemData ? (
          <span className='menu-icon'>{menuItemData?.icon}</span>
        ) : (
          <span className='menu-icon'>
            <KTSVG
              path='/media/biha/icons/svg/fluent_text-bullet-list-square-toolbox-20-filled.svg'
              className='svg-icon-2'
            />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('ki-duotone fs-3 me-2', fontIcon)}>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
