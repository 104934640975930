import {gt, isEmpty, size} from 'lodash'
import * as Yup from 'yup'
import {NAME_REGEX, PHONE_REGEX, Response} from '../../../../../_biha/helpers'
import {
  CountrySerialization,
  DistrictSerialization,
  GenderTypeEnum,
  ProvinceSerialization,
  ResponseAttachmentSerialization,
  ResponseImageSerialization,
  ResponseUserSerialization,
  StreetSerialization,
  String,
  WardSerialization,
} from '../../core/_models'

export interface AddressProps {
  districts?: {
    value?: {
      id: String
      name: String
    }
    label?: String
  }[]
  wards?: {
    value?: {
      id: String
      name: String
    }
    label?: String
  }[]
  streets?: {
    value?: {
      id: String
      name: String
    }
    label?: String
  }[]
}
export enum HistoryTypeEnum {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  RESTORE = 'RESTORE',
  PERMANENTLY_DELETE = 'PERMANENTLY_DELETE',
  VIEW_DETAIL = 'VIEW_DETAIL',
  RESUBMIT_APPROVAL = 'RESUBMIT_APPROVAL',
  PROCESS_REQUEST = 'PROCESS_REQUEST',
  APPROVE_REQUEST = 'APPROVE_REQUEST',
  REJECT_REQUEST = 'REJECT_REQUEST',
}

export enum TypeNotarizationEnum {
  FLUCTUATION = 'FLUCTUATION',
  BANK_LOANS = 'BANK_LOANS',
  NOTARIZATION = 'NOTARIZATION',
}
export enum PackageLoanEnum {
  PERSONAL_LOAN = 'PERSONAL_LOAN',
  COLLATERAL_LOAN = 'COLLATERAL_LOAN',
  CASH_ADVANCE = 'CASH_ADVANCE',
  INSTALLMENT_LOAN = 'INSTALLMENT_LOAN',
}
export enum PurposeLoanEnum {
  REAL_ESTATE_PURCHASE = 'REAL_ESTATE_PURCHASE',
  CAR_LOAN = 'CAR_LOAN',
  SECURED_CONSUMER_LOAN = 'SECURED_CONSUMER_LOAN',
  UNSECURED_CONSUMER_LOAN = 'UNSECURED_CONSUMER_LOAN',
  BUSINESS_CAPITAL_LOAN = 'BUSINESS_CAPITAL_LOAN',
  HOME_CONSTRUCTION_RENOVATION_LOAN = 'HOME_CONSTRUCTION_RENOVATION_LOAN',
  EDUCATION_LOAN = 'EDUCATION_LOAN',
  OTHER_PRODUCTS = 'OTHER_PRODUCTS',
}
export enum MaritalStatusEnum {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
}
export interface Attachments {
  images?: String[]
  attachments?: String[]
}
export enum StatusEnum {
  PENDING_PROCESSING = 'PENDING_PROCESSING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export enum SourceEnum {
  ATMNHA = 'ATMNHA',
  INTERNAL = 'INTERNAL',
}
export interface NotarizationLoanListSerialization {
  province?: ProvinceSerialization
  district?: DistrictSerialization
  ward?: WardSerialization
  street?: StreetSerialization
  fullNameReferrer?: String
  phoneReferrer?: String
  emailReferrer?: String
  provinceOffice?: ProvinceSerialization
  districtOffice?: DistrictSerialization
  source?: SourceEnum
  apartmentNumber?: String
  _id?: String
  code?: String
  typeNotarization?: TypeNotarizationEnum
  status?: StatusEnum
  idNotaryOffice?: String
  bank?: {
    id?: String
    name?: String
  }
  notaryOffice?: {
    id?: String
    name?: String
  }
  namePersonLoan?: String
  birthPersonLoan?: String
  genderPersonLoan?: GenderTypeEnum
  nationalityPersonLoan?: {
    name?: String
  }
  idCardPersonLoan?: String
  phonePersonLoan?: String
  emailPersonLoan?: String
  packageLoan?: PackageLoanEnum
  purposeLoan?: PurposeLoanEnum
  maritalStatusPersonLoan?: MaritalStatusEnum
  infoWifeHusband?: String
  carrierPersonLoan?: String
  incomePersonLoan?: String
  otherIncomePersonLoan?: String
  debtPersonLoan?: String
  infoMorePersonLoan?: String
  attachmentBorrower?: {
    images?: ResponseImageSerialization[]
    attachments?: ResponseAttachmentSerialization[]
  }
  attachmentCertificate?: {
    images?: ResponseImageSerialization[]
    attachments?: ResponseAttachmentSerialization[]
  }
  createdBy?: ResponseUserSerialization
  updatedBy?: ResponseUserSerialization
  approvedBy?: ResponseUserSerialization
  createdAt?: String
  updatedAt?: String
  approvedAt?: String
  reason?: string
}

export interface NotarizationLoanInternalDto {
  province?: ProvinceSerialization
  district?: DistrictSerialization
  ward?: WardSerialization
  street?: StreetSerialization
  apartmentNumber?: String
  fullNameReferrer?: String
  phoneReferrer?: String
  emailReferrer?: String
  namePersonLoan?: String
  birthPersonLoan?: String
  genderPersonLoan?: GenderTypeEnum
  nationalityPersonLoan?: CountrySerialization
  idCardPersonLoan?: String
  phonePersonLoan?: String
  emailPersonLoan?: String
  packageLoan?: PackageLoanEnum
  purposeLoan?: PurposeLoanEnum
  maritalStatusPersonLoan?: MaritalStatusEnum
  infoWifeHusband?: String
  carrierPersonLoan?: String
  incomePersonLoan?: String
  otherIncomePersonLoan?: String
  debtPersonLoan?: String
  infoMorePersonLoan?: String
  attachmentBorrower?: Attachments
  attachmentCertificate?: Attachments
}
export interface CreateBankLoanRequestFormValues {
  namePersonLoan?: String
  birthPersonLoan?: String
  genderPersonLoan?: GenderTypeEnum
  nationalityPersonLoan?: {
    name?: String
  }
  idCardPersonLoan?: String
  province?: {
    id: String
    name: String
  }
  district?: {
    id: String
    name: String
  }
  ward?: {
    id: String
    name: String
  }
  street?: {
    id: String
    name: String
  }
  apartmentNumber?: String
  phonePersonLoan?: String
  emailPersonLoan?: String
  packageLoan?: PackageLoanEnum
  purposeLoan?: PurposeLoanEnum
  maritalStatusPersonLoan?: MaritalStatusEnum
  infoWifeHusband?: String
  carrierPersonLoan?: String
  incomePersonLoan?: String
  otherIncomePersonLoan?: String
  debtPersonLoan?: String
  infoMorePersonLoan?: String
  fullNameReferrer?: String
  phoneReferrer?: String
  emailReferrer?: String
  personLoanImages?: String[]
  personLoanDocuments?: String[]
  certificationImages?: String[]
  certificationDocuments?: String[]
}
export interface BankLoanRequestsFilterFormValues {
  page?: number
  perPage?: number
  code?: String
  provinceOffice?: String
  districtOffice?: String
  bank?: String
  idNotaryOffice?: String
  namePersonLoan?: String
  birthPersonLoan?: String
  genderPersonLoan?: String[]
  nationalityPersonLoan?: String
  idCardPersonLoan?: String
  phonePersonLoan?: String
  emailPersonLoan?: String
  province?: String
  district?: String
  ward?: String
  street?: String
  apartmentNumber?: String
  packageLoan?: String[]
  purposeLoan?: String[]
  // status?: String[]
  createdAt?: String
  updatedAt?: String
  approvedAt?: String
  createdBy?: String[]
  updatedBy?: String
  approvedBy?: String[]
}
export interface BorrowerInformationFilterFormValues {
  namePersonLoan?: String
  birthPersonLoan?: String
  genderPersonLoan?: GenderTypeEnum
  nationalityPersonLoan?: String
  idCardPersonLoan?: String
  province?: String
  district?: String
  ward?: String
  street?: String
  apartmentNumber?: String
  phonePersonLoan?: String
  emailPersonLoan?: String
}
export interface ReasonDto {
  reason?: String
}
export const initialValuesBorrowerInformationFilterForm: BorrowerInformationFilterFormValues = {
  namePersonLoan: '',
  birthPersonLoan: '',
  genderPersonLoan: undefined,
  nationalityPersonLoan: 'vietnam',
  idCardPersonLoan: '',
  province: '',
  district: '',
  ward: '',
  street: '',
  apartmentNumber: '',
  phonePersonLoan: '',
  emailPersonLoan: '',
}
export const initialValuesBankLoanRequestsFilterForm: BankLoanRequestsFilterFormValues = {
  page: 1,
  perPage: undefined,
  code: '',
  provinceOffice: '',
  districtOffice: '',
  bank: '',
  idNotaryOffice: '',
  namePersonLoan: '',
  birthPersonLoan: '',
  genderPersonLoan: [],
  nationalityPersonLoan: '',
  idCardPersonLoan: '',
  phonePersonLoan: '',
  emailPersonLoan: '',
  province: '',
  district: '',
  ward: '',
  street: '',
  apartmentNumber: '',
  packageLoan: [],
  purposeLoan: [],
  // status: [],
  createdAt: '',
  updatedAt: '',
  approvedAt: '',
  createdBy: [],
  updatedBy: '',
  approvedBy: [],
}
export interface DesiredLoanFilterFormValues {
  packageLoan?: String[]
  purposeLoan?: String[]
}
export const initialValuesDesiredLoanFilterForm: DesiredLoanFilterFormValues = {
  packageLoan: [],
  purposeLoan: [],
}
export interface BankInformationFilterFormValues {
  provinceOffice?: String
  districtOffice?: String
  bank?: String
  idNotaryOffice?: String
}
export const initialValuesBankInformationFilterForm: BankInformationFilterFormValues = {
  provinceOffice: '',
  districtOffice: '',
  bank: '',
  idNotaryOffice: '',
}
export interface CreatorFilterFormValues {
  createdBy?: String[]
}
export interface ApprovedByFilterFormValues {
  approvedBy?: String[]
}
export const initialValuesCreatorFilterForm: CreatorFilterFormValues = {
  createdBy: [],
}
export const initialValuesApprovedByFilterForm: ApprovedByFilterFormValues = {
  approvedBy: [],
}
export interface DateFilterFormValues {
  createdAt?: String[]
  approvedAt?: String[]
}
export const initialValuesDateFilterForm: DateFilterFormValues = {
  createdAt: [],
  approvedAt: [],
}
export interface UserFilterFormValues {
  users?: String[]
}
export const initialValuesUserFilterForm: UserFilterFormValues = {
  users: [],
}
export const bankLoanRequestValidationSchema = Yup.object().shape({
  namePersonLoan: Yup.string()
    .max(118, 'Tối đa 118 ký tự')
    .matches(NAME_REGEX, 'Vui lòng nhập tên hợp lệ')
    .required('Vui lòng nhập tên'),
  birthPersonLoan: Yup.string()
    .notRequired()
    .test('valid-date', 'Định dạng thời gian không hợp lệ', (value) => {
      if (!value) return true
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/
      return dateRegex.test(value)
    }),
  idCardPersonLoan: Yup.string()
    .max(15, 'Tối đa 15 ký tự')
    .matches(/^\d{1,15}$/, 'Vui lòng nhập CMND/CCCD hợp lệ'),
  province: Yup.object().required('Vui lòng chọn tỉnh thành'),
  district: Yup.object().required('Vui lòng chọn quận/huyện'),
  ward: Yup.object().nullable(),
  street: Yup.object().required('Vui lòng chọn đường'),
  apartmentNumber: Yup.string(),
  phonePersonLoan: Yup.string()
    .required('Vui lòng nhập số điện thoại')
    .max(10, 'Tối đa 10 ký tự')
    .matches(PHONE_REGEX, 'Vui lòng nhập SĐT hợp lệ'),
  emailPersonLoan: Yup.string()
    .email('Không đúng định dạng email')
    .max(68, 'Tối đa 68 ký tự')
    .notRequired(),
  packageLoan: Yup.string().required('Vui lòng chọn gói vay'),
  purposeLoan: Yup.string().required('Vui lòng chọn mục đích vay'),
  infoWifeHusband: Yup.string().max(118, 'Tối đa 118 ký tự'),
  carrierPersonLoan: Yup.string().max(118, 'Tối đa 118 ký tự'),
  incomePersonLoan: Yup.string().max(118, 'Tối đa 118 ký tự'),
  otherIncomePersonLoan: Yup.string().max(888, 'Tối đa 888 ký tự'),
  debtPersonLoan: Yup.string().max(888, 'Tối đa 888 ký tự'),
  infoMorePersonLoan: Yup.string().max(888, 'Tối đa 888 ký tự'),
  fullNameReferrer: Yup.string()
    .max(118, 'Tối đa 118 ký tự')
    .matches(NAME_REGEX, 'Vui lòng nhập tên hợp lệ'),
  phoneReferrer: Yup.string()
    .max(10, 'Tối đa 10 ký tự')
    .matches(PHONE_REGEX, 'Vui lòng nhập SĐT hợp lệ'),
  emailReferrer: Yup.string()
    .email('Không đúng định dạng email')
    .max(68, 'Tối đa 68 ký tự')
    .notRequired(),
  personLoanDocuments: Yup.array()
    .of(Yup.string())
    .when('personLoanImages', {
      is: (images: String[]) => isEmpty(images),
      then: Yup.array().test(
        'at-least-value',
        'Bắt buộc phải tải lên ít nhất 01 hình ảnh hoặc 01 tài liệu',
        (docs) => {
          return gt(size(docs), 0)
        }
      ),
    }),
  certificationDocuments: Yup.array()
    .of(Yup.string())
    .when('certificationImages', {
      is: (images: String[]) => isEmpty(images),
      then: Yup.array().test(
        'at-least-value',
        'Bắt buộc phải tải lên ít nhất 01 hình ảnh hoặc 01 tài liệu',
        (docs) => {
          return gt(size(docs), 0)
        }
      ),
    }),
})
export const bankLoanRequestRejectValidationSchema = Yup.object().shape({
  reasonContent: Yup.string().when('reason', {
    is: 'other',
    then: Yup.string().max(888, 'Tối đa 888 ký tự').required('Nhập lý do từ chối'),
  }),
})
export const bankLoanRequestInitialValues: CreateBankLoanRequestFormValues = {
  namePersonLoan: '',
  birthPersonLoan: '',
  genderPersonLoan: GenderTypeEnum.MALE,
  nationalityPersonLoan: {
    name: '',
  },
  idCardPersonLoan: '',
  province: undefined,
  district: undefined,
  ward: undefined,
  street: undefined,
  apartmentNumber: '',
  phonePersonLoan: '',
  emailPersonLoan: '',
  packageLoan: undefined,
  purposeLoan: undefined,
  maritalStatusPersonLoan: MaritalStatusEnum.SINGLE,
  infoWifeHusband: '',
  carrierPersonLoan: '',
  incomePersonLoan: '',
  otherIncomePersonLoan: '',
  debtPersonLoan: '',
  infoMorePersonLoan: '',
  fullNameReferrer: '',
  phoneReferrer: '',
  emailReferrer: '',
  personLoanImages: [],
  personLoanDocuments: [],
  certificationImages: [],
  certificationDocuments: [],
}
export type NotarizationLoanInternalQueryResponse = Response<
  Array<NotarizationLoanListSerialization>
>
