import {FC, useContext} from 'react'
import {useQuery} from 'react-query'
import {
  FLUCTUATION_QUERIES,
  WithChildren,
  createPaginationResponseContext,
  initialQueryResponse,
} from '../../../../../_biha/helpers'
import {UserListSerialization} from './_models'
import {getUserList} from './_requests'

const QueryResponseContext =
  createPaginationResponseContext<Array<UserListSerialization>>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${FLUCTUATION_QUERIES.USER_LIST}`,
    () => {
      return getUserList()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response}}>
      {children}
    </QueryResponseContext.Provider>
  )
}
const useQueryResponse = () => useContext(QueryResponseContext)
const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  return response ?? []
}
const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading}
