const NOTARIZATION_MAIN_PATHNAME = 'admin/notarization-requests'
const FLUCTUATION_MAIN_PATHNAME = 'admin/fluctuation-requests'
const BANK_MAIN_PATHNAME = 'admin/bank-loan-requests'
const DEPOSIT_TRANSACTION_PATHNAME = 'admin/deposit-transaction'
const UPGRADE_ACCOUNT_PATHNAME = 'admin/upgrade-account'
const QUERIES = {
  CONFIG: 'config',
}
const DEPOSIT_TRANSACTION_QUERIES = {
  CONFIG: 'config',
  DEPOSIT_TRANSACTION_LIST: 'deposit-transaction-list',
  COMMENT: 'comment',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
}
const UPGRADE_ACCOUNT_QUERIES = {
  CONFIG: 'config',
  CUSTOMERS_LIST: 'customers-list',
  DEMAND_LIST: 'demand-list',
  PROVINCE_LIST: 'province-list',
  DISTRICT_LIST: 'district-list',
  WARD_LIST: 'ward-list',
  STREET_LIST: 'street-list',
  COMMENT: 'comment',
  UPGRADE_ACCOUNT_LIST: 'upgrade-account-list',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
}
const FLUCTUATION_QUERIES = {
  CONFIG: 'config',
  PROVINCE_LIST: 'province-list',
  COMMENT: 'comment',
  FLUCTUATION_REQUESTS: 'fluctuation-requests',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  USER_LIST: 'user-list',
}
const NOTARIZATION_QUERIES = {
  CONFIG: 'config',
  PROVINCE_LIST: 'province-list',
  COMMENT: 'comment',
  NOTARIZATION_REQUESTS: 'notarization-requests',
  USER_LIST: 'user-list',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
}
const BANK_QUERIES = {
  CONFIG: 'config',
  PROVINCE_LIST: 'province-list',
  COMMENT: 'comment',
  BANK_LOAN_REQUESTS: 'bank-loan-requests',
  BANK_LIST: 'bank-list',
  USER_LIST: 'user-list',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
}

const NOTARIZATION_SUB_PATHNAME = {
  LIST: 'list',
  OVERVIEW: 'overview',
  RESUBMIT: 'resubmit',
  SEND_APPROVE: 'send-approve',
  NEW: 'new',
  EDIT: 'edit',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  NOTE: 'note',
  SLUG_ID: ':id',
}
const FLUCTUATION_SUB_PATHNAME = {
  EDIT: 'edit',
  NEW: 'new',
  RESUBMIT: 'resubmit',
  SEND_APPROVE: 'send-approve',
  LIST: 'list',
  OVERVIEW: 'overview',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  NOTE: 'note',
  SLUG_ID: ':id',
}
const BANK_SUB_PATHNAME = {
  EDIT: 'edit',
  NEW: 'new',
  RESUBMIT: 'resubmit',
  SEND_APPROVE: 'send-approve',
  LIST: 'list',
  OVERVIEW: 'overview',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  NOTE: 'note',
  SLUG_ID: ':id',
}
const UPGRADE_ACCOUNT_SUB_PATHNAME = {
  LIST: 'list',
  OVERVIEW: 'overview',
  RESUBMIT: 'resubmit',
  SEND_APPROVE: 'send-approve',
  NEW: 'new',
  EDIT: 'edit',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  NOTE: 'note',
  SLUG_ID: ':id',
}
const DEPOSIT_TRANSACTION_SUB_PATHNAME = {
  LIST: 'list',
  OVERVIEW: 'overview',
  EDIT: 'edit',
  EDIT_HISTORY: 'edit-history',
  INTERACTION_HISTORY: 'interaction-history',
  NOTE: 'note',
  SLUG_ID: ':id',
}
const SPECIAL_KEYS = [
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowTop',
  'ArrowBottom',
  'Delete',
  'Backspace',
  'Enter',
]
const PHONE_REGEX = /^(0)(3[2-9]|5[2689]|7[0|6-9]|8[1-9]|9[0-9])[0-9]{7}$/
const NAME_REGEX = /^[A-Za-zÀ-ỹ\s]+$/
export {
  BANK_MAIN_PATHNAME,
  BANK_QUERIES,
  BANK_SUB_PATHNAME,
  DEPOSIT_TRANSACTION_PATHNAME,
  DEPOSIT_TRANSACTION_QUERIES,
  DEPOSIT_TRANSACTION_SUB_PATHNAME,
  FLUCTUATION_MAIN_PATHNAME,
  FLUCTUATION_QUERIES,
  FLUCTUATION_SUB_PATHNAME,
  NAME_REGEX,
  NOTARIZATION_MAIN_PATHNAME,
  NOTARIZATION_QUERIES,
  NOTARIZATION_SUB_PATHNAME,
  PHONE_REGEX,
  QUERIES,
  SPECIAL_KEYS,
  UPGRADE_ACCOUNT_PATHNAME,
  UPGRADE_ACCOUNT_QUERIES,
  UPGRADE_ACCOUNT_SUB_PATHNAME,
}
