/* eslint-disable react/jsx-no-target-blank */
import {Fragment} from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import {find, includes, map} from 'lodash'
import {adminRouters} from '../../header/header-menus/routers'
import {useLocation} from 'react-router-dom'

const SidebarMenuMain = () => {
  const {pathname} = useLocation()
  const router = find(adminRouters, (router) => includes(pathname, router?.mainPath))

  return (
    <Fragment>
      {map(router?.subPath, (path, pathIndex) => (
        <SidebarMenuItem
          key={pathIndex}
          to={`/${path?.childrenPath}`}
          title={path?.title}
          param={path?.param}
        />
      ))}
    </Fragment>
  )
}

export {SidebarMenuMain}
