import {find, includes} from 'lodash'
import {useLocation} from 'react-router-dom'
import {KTSVG} from '../../../../helpers/components/KTSVG'
import {HorizontalMenu} from './HorizontalMenu'
import {adminRouters} from './routers'

export function MenuInner() {
  const {pathname} = useLocation()
  const router = find(adminRouters, (router) => includes(pathname, router?.mainPath))

  return (
    <div className='app-navbar flex-shrink-0'>
      <div
        className='app-navbar-item cursor-pointer symbol h-full'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <div className='me-3 text-white fw-bold'>
          <KTSVG path='/media/icons/modules/total-icon.svg' className='svg-icon-2x' />
        </div>
      </div>
      <HorizontalMenu />
      <div className='d-flex align-items-center text-white fw-bold'>
        <div className='menu-item me-lg-1 user-select-none'>
          <div className='menu-label fs-4 py-3'>
            <span className='menu-title'>{router?.title}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
