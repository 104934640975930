import {FC, createContext, useContext} from 'react'
import {WithChildren} from '../../../../_biha/helpers'
import {QueryResponseProvider, useQueryResponseData} from './core/QueryResponseProvider'
import {EwalletConfiguration} from './core/_models'

interface ConfigurationContextProps {
  filterConfigurations?: EwalletConfiguration
}
const ConfigurationContext = createContext<ConfigurationContextProps>({})

const ConfigurationWrapper: FC<WithChildren> = ({children}) => {
  const filterConfigurations = useQueryResponseData()
  return (
    <ConfigurationContext.Provider value={{filterConfigurations}}>
      {children}
    </ConfigurationContext.Provider>
  )
}

const ConfigurationGraphqlProvider: FC<WithChildren> = ({children}) => {
  return (
    <QueryResponseProvider>
      <ConfigurationWrapper>{children}</ConfigurationWrapper>
    </QueryResponseProvider>
  )
}

const useConfigurations = () => useContext(ConfigurationContext)

export {ConfigurationGraphqlProvider, useConfigurations}
