import {isEmpty, map} from 'lodash'
import {FC, Fragment, Suspense, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_biha/assets/ts/_utils'
import {
  BANK_MAIN_PATHNAME,
  DEPOSIT_TRANSACTION_PATHNAME,
  FLUCTUATION_MAIN_PATHNAME,
  NOTARIZATION_MAIN_PATHNAME,
  UPGRADE_ACCOUNT_PATHNAME,
  WithChildren,
} from '../../_biha/helpers'
import {MasterLayout} from '../../_biha/layout/MasterLayout'
import {Module, useAuth} from '../modules/auth'
import {Error403} from '../modules/errors/components/Error403'

const BankRequestPage = lazy(() =>
  import('../modules/apps/bank-loan-request-management/BankLoanRequestsPage').then((module) => ({
    default: module.BankLoanRequestsPage,
  }))
)
const FluctuationRequestPage = lazy(() =>
  import('../modules/apps/fluctuation-request-management/FluctuationRequestPage').then(
    (module) => ({
      default: module.FluctuationRequestPage,
    })
  )
)
const NotarizationRequestPage = lazy(() =>
  import('../modules/apps/notarization-request-management/NotarizationRequestPage').then(
    (module) => ({
      default: module.NotarizationRequestPage,
    })
  )
)
const UpgradeAccountPage = lazy(() =>
  import('../modules/apps/upgrade-account-management/UpgradeAccountsPage').then((module) => ({
    default: module.UpgradeAccountsPage,
  }))
)
const DepositTransactionPage = lazy(() =>
  import('../modules/apps/deposit-transaction-management/DepositTransactionsPage').then(
    (module) => ({
      default: module.DepositTransactionsPage,
    })
  )
)

const PrivateRoutes = () => {
  const {currentUser} = useAuth()

  let initialNavigate: string
  let routes: React.ReactElement[] = []

  ;(() => {
    map(currentUser?.modules, (module) => {
      switch (module) {
        case Module.ADMIN_LOAN:
          routes.push(
            <Route
              path={`${BANK_MAIN_PATHNAME}/*`}
              element={
                <SuspensedView>
                  <BankRequestPage />
                </SuspensedView>
              }
            />
          )
          initialNavigate = `/${BANK_MAIN_PATHNAME}`
          break
        case Module.ADMIN_NOTARIZATION:
          routes.push(
            <Route
              path={`${NOTARIZATION_MAIN_PATHNAME}/*`}
              element={
                <SuspensedView>
                  <NotarizationRequestPage />
                </SuspensedView>
              }
            />
          )
          initialNavigate = `/${NOTARIZATION_MAIN_PATHNAME}`
          break
        case Module.ADMIN_REGISTER:
          routes.push(
            <Route
              path={`${FLUCTUATION_MAIN_PATHNAME}/*`}
              element={
                <SuspensedView>
                  <FluctuationRequestPage />
                </SuspensedView>
              }
            />
          )
          initialNavigate = `/${FLUCTUATION_MAIN_PATHNAME}`
          break
        case Module.ADMIN_UPGRADE_ACCOUNT:
          routes.push(
            <Route
              path={`${UPGRADE_ACCOUNT_PATHNAME}/*`}
              element={
                <SuspensedView>
                  <UpgradeAccountPage />
                </SuspensedView>
              }
            />
          )
          initialNavigate = `/${UPGRADE_ACCOUNT_PATHNAME}`
          break
        case Module.ADMIN_FINANCE:
          routes.push(
            <Route
              path={`${DEPOSIT_TRANSACTION_PATHNAME}/*`}
              element={
                <SuspensedView>
                  <DepositTransactionPage />
                </SuspensedView>
              }
            />
          )
          initialNavigate = `/${DEPOSIT_TRANSACTION_PATHNAME}`
          break
      }
    })
  })()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {currentUser ? (
          isEmpty(routes) ? (
            <Route path='*' element={<Error403 />} />
          ) : (
            <>
              {map(routes, (route, routeIndex) => (
                <Fragment key={routeIndex}>
                  <>
                    {route}
                    <Route path='auth/*' element={<Navigate to={initialNavigate} />} />
                  </>
                </Fragment>
              ))}
            </>
          )
        ) : (
          <Route path='*' element={<Navigate to='/error/404' />} />
        )}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
