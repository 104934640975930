import * as Yup from 'yup'
import {ResponseGraphQL} from '../../../../../_biha/helpers'
import {Boolean, DateTime, Int, Number, String} from '../../../auth'

export enum ApproveStatusEnum {
  approved = 'approved',
  rejected = 'rejected',
  pending = 'pending',
}
export interface InternalFromToDateStringInputType {
  from?: String
  to?: String
}
export enum StatusTransactionEnum {
  PAID = 'PAID',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}
export enum StatusUpgradeRequestEnumType {
  PROCESSING = 'PROCESSING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
export enum Rank {
  basic = 'basic',
  gold = 'gold',
  diamond = 'diamond',
}
export enum PaymentMethodEnum {
  VNPAY = 'VNPAY',
  BANK_TRANSFER = 'BANK_TRANSFER',
}
export enum HistoryUpgradeRequestEnumType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DETAILS = 'DETAILS',
}
export enum PaymentTypeEnum {
  VNPAY = 'VNPAY',
  LATER = 'LATER',
}
export interface PaymentErrorType {
  code?: String
  errorMessage?: String
}
export interface PaymentPayloadType {
  transactionId?: String
  paymentUrl?: String
  redirectUrl?: String
}

export interface PaginationGrapqhQLState extends PaginationOf {
  links?: {
    active: boolean
    disabled: boolean
    label: string | undefined
    page: number | undefined
  }[]
}
export interface LoadMoreState extends Omit<PaginationGrapqhQLState, 'perPage'> {
  until?: string | undefined
}
export interface SortState {
  availableSort?: string
  optionSort?: string
}

export interface FilterState {
  filter?: unknown
}

export interface SearchState {
  search?: string
}
export type QueryState = PaginationGrapqhQLState &
  SortState &
  FilterState &
  SearchState &
  LoadMoreState
export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
}
export type UpgradeAccountQueryResponse = ResponseGraphQL<ListUpgradeRequestQueryData>
export interface ListUpgradeRequestQueryVariables {
  page?: Number
  sort?: String
  searchString?: String
  limit?: Number
  requestId?: String
  customerName?: String
  email?: String
  phone?: String
  idCustomer?: String
  statusTransaction?: StatusTransactionEnum[]
  status?: StatusUpgradeRequestEnumType[]
  rank?: Rank[]
  paymentMethod?: PaymentMethodEnum[]
  bankCode?: String
  bankOwner?: String
  transactionId?: String
  amount?: Rank[]
  createdDate?: InternalFromToDateStringInputType
  invoiceDate?: InternalFromToDateStringInputType
  paymentType?: PaymentTypeEnum[]
}
export interface UpgradeRequestOutputType {
  approvedAt?: String
  approvedBy?: B2BUserType
  createdAt?: String
  editor?: B2BUserType
  expired?: Boolean
  fts?: String
  historyType?: HistoryUpgradeRequestEnumType
  id?: String
  owner?: B2CUser
  paymentInfo?: TransactionType
  rank?: Rank
  reason?: String
  requestId?: String
  status?: StatusUpgradeRequestEnumType
  updatedAt?: String
  isDisabled?: Boolean
}
export interface B2CUser {
  id?: String
  fullName?: String
  phones?: String[]
  email?: String
  avatar?: {
    fileId?: String
    originalUrl?: String
  }
  memberId?: String
}
export interface TransactionType {
  orderId?: String
  amount?: Number
  body?: String
  bankCode?: String
  cardType?: String
  bankAccountNumber?: String
  status?: StatusTransactionEnum
  paymentError?: PaymentErrorType
  paymentType?: PaymentTypeEnum
  paymentMethod?: PaymentMethodEnum
  paymentPayload?: PaymentPayloadType
  bankOwner?: String
  ipAddress?: String
  invoiceDate?: DateTime
  transactionId?: String
}
export interface ListUpgradeRequestQueryData {
  listUpgradeRequest?: ListUpgradeRequestQueryPayload
}
export interface ListUpgradeRequestQueryPayload extends PaginationOf {
  items?: UpgradeRequestOutputType[]
  totalPrepayment?: number
  totalPaymentLater?: number
}
export interface PaginationOf {
  hasNextPage?: boolean
  hasPrevPage?: boolean
  limit?: number
  nextPage?: number
  page?: number
  pagingCounter?: number
  prevPage?: number
  total?: number
  totalPages?: number
}

export interface B2BUserType {
  id?: string
  userType?: string
  fullName?: string
  employeeCode?: string
  email?: string
  avatar?: AnotherServiceFileType
  gender?: string
  phone?: string
  username?: string
  secondPhone?: string
  province?: AddressType
  district?: AddressType
  ward?: AddressType
  street?: AddressType
  houseNumber?: string
  company?: string
  idNumber?: string
  dateOfBirth?: String
  birthDate?: String
  creator?: B2BUserType
  editor?: B2BUserType
  idImages?: AnotherServiceFileType[]
  approveStatus?: ApproveStatusOutputType
  approvedBy?: B2BUserType
  createdAt?: String
  updatedAt?: String
  approvedAt?: String
  role?: string
  position?: string
  isLocked?: boolean
  deviceToken?: string
  attachments?: AnotherServiceFileType[]
  departments?: EmployeeDepartmentType[]
  employeeDepartments?: string[]
  projectEmployees?: string[]
  productEmployees?: string[]
  productPermissions?: PermissionOutputType[]
  projectPermissions?: PermissionOutputType[]
  b2cPermissions?: PermissionOutputType[]
  deletedAt?: String
  amount?: number
  lastOnline?: String
  lockByRtAt?: String
  lockedByBo?: string
  isPersonal?: boolean
  permissionGroup?: string
  permissions?: PermissionOutputType[]
  permissionEditable?: boolean
}
export type AnotherServiceFileType = MediaFile | YoutubeFile
export interface MediaFile {
  id?: String
  fileId?: String
  type?: String
  originalUrl?: String
  youtubeMetaData?: any
  creator?: String
  createdAt?: String
  urls?: {
    url?: String
  }[]
  loading?: boolean
  readMediaUrls?: MediaFile
  originalKey?: String
}
export interface YoutubeFile {
  id?: String
  fileId?: String
  type?: String
  originalUrl?: String
  youtubeMetadata?: {
    videoId?: String
    thumbnailUrl?: String
    title?: String
  }
}
export interface AddressType {
  id?: String
  name?: String
}
export interface ApproveStatusOutputType {
  status?: String
  reasonType?: String
  reason?: String
}
export interface EmployeeDepartmentType {
  createdAt?: String
  creator?: B2BUserType
  deletedAt?: String
  deletedBy?: B2BUserType
  department?: EmployeeDepartmentType
  id?: String
  manager?: EmployeeDepartmentType
  positionName?: String
  positionType?: String
  updatedAt?: String
  user?: any
  editor?: any
}
export interface PermissionOutputType {
  id?: string
  name?: string
  configs?: any
}
export interface ListUpgradeRequestFilterFormValues {
  page?: Number
  sort?: String
  limit?: Number
  requestId?: String
  customerName?: String
  email?: String
  phone?: String
  idCustomer?: String
  statusTransaction?: String[]
  status?: String[]
  rank?: String[]
  paymentMethod?: String[]
  bankCode?: String
  bankOwner?: String
  transactionId?: String
  amount?: String
  createdDate?: String[]
  invoiceDate?: String[]
  bankAccountNumber?: String
  paymentType?: String
}
export const initialValuesListUpgradeRequestFilterForm: ListUpgradeRequestFilterFormValues = {
  page: 1,
  sort: '',
  limit: undefined,
  requestId: '',
  customerName: '',
  email: '',
  phone: '',
  idCustomer: '',
  statusTransaction: [],
  status: [],
  rank: [],
  paymentMethod: [],
  bankCode: '',
  bankOwner: '',
  transactionId: '',
  amount: '',
  createdDate: [],
  invoiceDate: [],
  paymentType: '',
  bankAccountNumber: '',
}
export interface UpgradeAccountDateFilterFormValues {
  createdDate?: String[]
  invoiceDate?: String[]
}
export const initialValuesUpgradeAccountDateFilterForm: UpgradeAccountDateFilterFormValues = {
  createdDate: [],
  invoiceDate: [],
}
export interface GetDetailsRequestUpgradeByUserVariables {
  id?: String
  requestId?: String
}
export interface UpdatePaymentInfoInputType {
  status?: StatusTransactionEnum
  bankOwner?: String
  bankCode?: String
  bankAccountNumber?: String
  amount?: Int
  transactionId?: String
  body?: String
  invoiceDate?: DateTime
}
export interface UpdateRequestPaymentQueryVariables {
  id: String
  status?: StatusUpgradeRequestEnumType
  reason?: String
  input?: UpdatePaymentInfoInputType
}
export interface UpdateRequestPaymentFormValues {
  status?: StatusTransactionEnum
  requestStatus?: StatusUpgradeRequestEnumType
  bankOwner?: String
  bankCode?: String
  bankAccountNumber?: String
  amount?: String
  transactionId?: String
  body?: String
  invoiceDate?: DateTime
  reason?: String
  reasonContent?: String
}
export interface UpgradeAccountExtrasFilterFormValues {
  rank?: string
  paymentMethod?: string
  bankCode?: string
  bankOwner?: string
  bankAccountNumber?: string
  amount?: string
  transactionId?: string
  paymentType?: string
}
export const initialValuesUpgradeAccountExtraFilterForm: UpgradeAccountExtrasFilterFormValues = {
  rank: '',
  paymentMethod: '',
  bankCode: '',
  bankOwner: '',
  bankAccountNumber: '',
  amount: '',
  transactionId: '',
  paymentType: '',
}
export interface UpdateTableSettingsQueryVariables {
  input: TableInput
}
export interface TableInput {
  tableId: String
  columns: ColumnInput[]
}
export interface ColumnInput {
  checked?: Boolean
  id?: String
  label?: String
}
export interface UpdateTableSettingsQueryData {
  updateTableSettings?: {
    success?: Boolean
    msg?: String
    payload?: String
  }
}
export const upgradeRequestEditValidationSchema = Yup.object().shape({
  status: Yup.string().required('Vui lòng chọn trạng thái thanh toán'),
  bankOwner: Yup.string().when('status', {
    is: 'REJECTED',
    then: Yup.string().max(118, 'Tối đa 118 ký tự').notRequired(),
    otherwise: Yup.string().max(118, 'Tối đa 118 ký tự').required('Vui lòng nhập chủ tài khoản'),
  }),
  bankCode: Yup.string().when('status', {
    is: 'REJECTED',
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required('Vui lòng chọn ngân hàng'),
  }),
  bankAccountNumber: Yup.string().when('status', {
    is: 'REJECTED',
    then: Yup.string().max(118, 'Tối đa 118 ký tự').notRequired(),
    otherwise: Yup.string().max(118, 'Tối đa 118 ký tự').required('Vui lòng nhập số tài khoản'),
  }),
  amount: Yup.string().when('status', {
    is: 'REJECTED',
    then: Yup.string().max(19, 'Tối đa 19 ký tự').notRequired(),
    otherwise: Yup.string()
      .required('Vui lòng nhập số tiền thanh toán')
      .max(19, 'Quá giới hạn cho phép'),
  }),
  transactionId: Yup.string().when('status', {
    is: 'REJECTED',
    then: Yup.string().max(118, 'Tối đa 118 ký tự').notRequired(),
    otherwise: Yup.string().max(118, 'Tối đa 118 ký tự').required('Vui lòng nhập ID thanh toán'),
  }),
  body: Yup.string().max(118, 'Tối đa 118 ký tự').notRequired(),
  invoiceDate: Yup.string().when('status', {
    is: 'REJECTED',
    then: Yup.string()
      .test('valid-date', 'Định dạng thời gian không hợp lệ', (value) => {
        if (!value) return true
        const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/
        return dateTimeRegex.test(value)
      })
      .notRequired(),
    otherwise: Yup.string()
      .required('Vui lòng chọn ngày nhận thanh toán')
      .test('valid-date', 'Định dạng thời gian không hợp lệ', (value) => {
        if (!value) return true
        const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/
        return dateTimeRegex.test(value)
      }),
  }),
  reasonContent: Yup.string().when('reason', {
    is: 'other',
    then: Yup.string().max(888, 'Tối đa 888 ký tự'),
  }),
})
